/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.content-fill').imagefill();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        //ScrollAnimation.refresh();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        //jQuery.getScript('http://rawgit.com/positionly/Typist/master/dist/typist.js', function () {
          jQuery(function () {
            //var typist;

            typist = document.querySelector("#typist-element");

            new Typist(typist, {
              letterInterval: 60,
              textInterval: 3000
            });
          });
        // });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {

            // $(function() {
              var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                  now = new Date(),
                  offset = -7 * 3600 * 1000,
                  utc = now.getTime() + (now.getTimezoneOffset() * 60000),
                  Digital = new Date(utc + offset),
                  month = Digital.getMonth() + 1,
                  hour = Digital.getHours(),
                  minute = Digital.getMinutes(),
                  day = Digital.getDay(),
                  currentTime = hour * 100 + minute;
                  holidays = ['10/30/2015','11/26/2015','11/27/2015','12/24/2015','12/25/2015','1/1/2016'];
                  fancyDate =  month + "/" + Digital.getDate() + "/" + Digital.getFullYear();

                  $("[data-day]").text(dayNames[day]);

              var openMF = (8 < hour && currentTime < 1630) && (0 < day && day < 6) ? true : false,
                  openS = (8 < hour && currentTime < 1630) && (day === 6) ? true : false;
                  isHoliday = ( $.inArray( fancyDate, holidays ) > -1 ) ? true : false;


                if(isHoliday){
                  $("[data-open]").text("Closed for the holiday");
                } else {
                  if(openMF || openS){
                    $("[data-open]").text("Open");
                    if(openS){
                      $("[data-odetails]").text(" for extended service hours");
                    } else{
                      $("[data-odetails]").text(" until 4:30pm");
                    }
                  } else {
                    if(day === 5 && 8 < hour){ // Friday
                      console.log("Closed, Open 8am Saturday");
                      $("[data-open]").text("Closed");
                      $("[data-odetails]").text(", we’re open tomorrow for extended service hours. Leave a message with our service department");
                    } else if( (day === 6 && 8 < hour) || day === 0 ) { // Saturday or Sunday
                      console.log("Closed, Open 8am Monday");
                      $("[data-open]").text("Closed");
                      $("[data-odetails]").text(", we’ll return at 8am Monday");
                    } else{
                      $("[data-open]").text("Closed");
                      if( 8 > hour){
                        $("[data-odetails]").text(", we open at 8am today");
                      }else{
                        $("[data-odetails]").text(", we open at 8am tomorrow");
                      }
                    }
                  }
                }
      }
    },

    'commercial_hvac': {
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
